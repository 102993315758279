/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum LocationStatusEnum {
    Suspended = 0,
    Active = 1,
    Inactive = 2,
}

export const LocationStatusEnumDisplayMap : { [key in LocationStatusEnum] : string } = {
    [LocationStatusEnum.Suspended]: "Suspended",
    [LocationStatusEnum.Active]: "Active",
    [LocationStatusEnum.Inactive]: "Inactive",
}
