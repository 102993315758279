/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum IdentifierUseEnum {
    Usual = 0,
    Secondary = 1,
    Official = 2,
    Old = 3,
    Temp = 4,
}

export const IdentifierUseEnumDisplayMap : { [key in IdentifierUseEnum] : string } = {
    [IdentifierUseEnum.Usual]: "Usual",
    [IdentifierUseEnum.Secondary]: "Secondary",
    [IdentifierUseEnum.Official]: "Official",
    [IdentifierUseEnum.Old]: "Old",
    [IdentifierUseEnum.Temp]: "Temp",
}
