/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ContactPointSystemEnum {
    Fax = 0,
    Pager = 1,
    Url = 2,
    Phone = 3,
    Email = 4,
    Other = 5,
    Sms = 6,
}

export const ContactPointSystemEnumDisplayMap : { [key in ContactPointSystemEnum] : string } = {
    [ContactPointSystemEnum.Fax]: "Fax",
    [ContactPointSystemEnum.Pager]: "Pager",
    [ContactPointSystemEnum.Url]: "Url",
    [ContactPointSystemEnum.Phone]: "Phone",
    [ContactPointSystemEnum.Email]: "Email",
    [ContactPointSystemEnum.Other]: "Other",
    [ContactPointSystemEnum.Sms]: "Sms",
}
