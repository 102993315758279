/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum NameUseEnum {
    Maiden = 0,
    Official = 1,
    Nickname = 2,
    Anonymous = 3,
    Usual = 4,
    Old = 5,
    Temp = 6,
}

export const NameUseEnumDisplayMap : { [key in NameUseEnum] : string } = {
    [NameUseEnum.Maiden]: "Maiden",
    [NameUseEnum.Official]: "Official",
    [NameUseEnum.Nickname]: "Nickname",
    [NameUseEnum.Anonymous]: "Anonymous",
    [NameUseEnum.Usual]: "Usual",
    [NameUseEnum.Old]: "Old",
    [NameUseEnum.Temp]: "Temp",
}
