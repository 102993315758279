/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum AddressTypeEnum {
    Physical = 0,
    Postal = 1,
    Both = 2,
}

export const AddressTypeEnumDisplayMap : { [key in AddressTypeEnum] : string } = {
    [AddressTypeEnum.Physical]: "Physical",
    [AddressTypeEnum.Postal]: "Postal",
    [AddressTypeEnum.Both]: "Both",
}
