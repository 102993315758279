/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ContactPointUseEnum {
    Home = 0,
    Work = 1,
    Temp = 2,
    Old = 3,
    Mobile = 4,
}

export const ContactPointUseEnumDisplayMap : { [key in ContactPointUseEnum] : string } = {
    [ContactPointUseEnum.Home]: "Home",
    [ContactPointUseEnum.Work]: "Work",
    [ContactPointUseEnum.Temp]: "Temp",
    [ContactPointUseEnum.Old]: "Old",
    [ContactPointUseEnum.Mobile]: "Mobile",
}
