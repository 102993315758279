/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OrderTypeEnum {
    LabOrder = 0,
    RadiologyOrder = 1,
    AncillaryOrder = 2,
    MedicationOrder = 3,
}

export const OrderTypeEnumDisplayMap : { [key in OrderTypeEnum] : string } = {
    [OrderTypeEnum.LabOrder]: "LabOrder",
    [OrderTypeEnum.RadiologyOrder]: "RadiologyOrder",
    [OrderTypeEnum.AncillaryOrder]: "AncillaryOrder",
    [OrderTypeEnum.MedicationOrder]: "MedicationOrder",
}
