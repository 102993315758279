/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum SummaryTypeEnum {
    Count = 0,
    Text = 1,
    Data = 2,
    True = 3,
    False = 4,
}

export const SummaryTypeEnumDisplayMap : { [key in SummaryTypeEnum] : string } = {
    [SummaryTypeEnum.Count]: "Count",
    [SummaryTypeEnum.Text]: "Text",
    [SummaryTypeEnum.Data]: "Data",
    [SummaryTypeEnum.True]: "True",
    [SummaryTypeEnum.False]: "False",
}
