/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum EncounterStatusEnum {
    Arrived = 0,
    Triaged = 1,
    InProgress = 2,
    Planned = 3,
    EnteredInError = 4,
    Onleave = 5,
    Cancelled = 6,
    Finished = 7,
    Unknown = 8,
}

export const EncounterStatusEnumDisplayMap : { [key in EncounterStatusEnum] : string } = {
    [EncounterStatusEnum.Arrived]: "Arrived",
    [EncounterStatusEnum.Triaged]: "Triaged",
    [EncounterStatusEnum.InProgress]: "InProgress",
    [EncounterStatusEnum.Planned]: "Planned",
    [EncounterStatusEnum.EnteredInError]: "EnteredInError",
    [EncounterStatusEnum.Onleave]: "Onleave",
    [EncounterStatusEnum.Cancelled]: "Cancelled",
    [EncounterStatusEnum.Finished]: "Finished",
    [EncounterStatusEnum.Unknown]: "Unknown",
}
