/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum QuestionnaireItemTypeEnum {
    Display = 0,
    Decimal = 1,
    Quantity = 2,
    Url = 3,
    String = 4,
    Attachment = 5,
    Boolean = 6,
    Choice = 7,
    Date = 8,
    DateTime = 9,
    Group = 10,
    Integer = 11,
    OpenChoice = 12,
    Question = 13,
    Reference = 14,
    Text = 15,
    Time = 16,
    Unknown = 17,
}

export const QuestionnaireItemTypeEnumDisplayMap : { [key in QuestionnaireItemTypeEnum] : string } = {
    [QuestionnaireItemTypeEnum.Display]: "Display",
    [QuestionnaireItemTypeEnum.Decimal]: "Decimal",
    [QuestionnaireItemTypeEnum.Quantity]: "Quantity",
    [QuestionnaireItemTypeEnum.Url]: "Url",
    [QuestionnaireItemTypeEnum.String]: "String",
    [QuestionnaireItemTypeEnum.Attachment]: "Attachment",
    [QuestionnaireItemTypeEnum.Boolean]: "Boolean",
    [QuestionnaireItemTypeEnum.Choice]: "Choice",
    [QuestionnaireItemTypeEnum.Date]: "Date",
    [QuestionnaireItemTypeEnum.DateTime]: "DateTime",
    [QuestionnaireItemTypeEnum.Group]: "Group",
    [QuestionnaireItemTypeEnum.Integer]: "Integer",
    [QuestionnaireItemTypeEnum.OpenChoice]: "OpenChoice",
    [QuestionnaireItemTypeEnum.Question]: "Question",
    [QuestionnaireItemTypeEnum.Reference]: "Reference",
    [QuestionnaireItemTypeEnum.Text]: "Text",
    [QuestionnaireItemTypeEnum.Time]: "Time",
    [QuestionnaireItemTypeEnum.Unknown]: "Unknown",
}
