/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum GenderEnum {
    Unknown = 0,
    Male = 1,
    Female = 2,
    Other = 3,
}

export const GenderEnumDisplayMap : { [key in GenderEnum] : string } = {
    [GenderEnum.Unknown]: "Unknown",
    [GenderEnum.Male]: "Male",
    [GenderEnum.Female]: "Female",
    [GenderEnum.Other]: "Other",
}
