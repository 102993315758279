/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CareTeamStatusEnum {
    Inactive = 0,
    EnteredInError = 1,
    Suspended = 2,
    Proposed = 3,
    Active = 4,
    Unknown = 5,
}

export const CareTeamStatusEnumDisplayMap : { [key in CareTeamStatusEnum] : string } = {
    [CareTeamStatusEnum.Inactive]: "Inactive",
    [CareTeamStatusEnum.EnteredInError]: "EnteredInError",
    [CareTeamStatusEnum.Suspended]: "Suspended",
    [CareTeamStatusEnum.Proposed]: "Proposed",
    [CareTeamStatusEnum.Active]: "Active",
    [CareTeamStatusEnum.Unknown]: "Unknown",
}
